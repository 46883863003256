import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import { StaticImage } from "gatsby-plugin-image"
// styles
import "../styles/hero.css"
const pageStyles = {
  color: "#ffffff",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  color: "#ffffff",
  position: 'absolute',
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  const image = getImage('../images/hero-bg.jpg')
  const bgImage = convertToBgImage(image)
  return (
    <div>
   
    

      
    <Layout>
   
    <section className="hero-404 d-flex justify-content-center align-items-center relative h-100">
      <figure>
          <StaticImage
            src="../images/hero-bg.jpg"
            quality={100}
            formats={["jpg"]}
            alt="Hero"
            loading="eager"
            placeholder="white"
            style={{ backgroundColor: 'transparent' }}
          />
      </figure>
      <div className="wrapper " style={headingStyles}>
        <h1 className="color-white" data-sal="slide-up" data-sal-delay="400" data-sal-duration="400" data-sal-easing="ease">Page not found</h1>
        
      </div>
    </section>
    {/* <main style={pageStyles}>
      <title>Not found</title>
      
      
    </main> */}
    </Layout>
    </div>
  )
}

export default NotFoundPage
